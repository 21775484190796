.container {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -8px -8px 0;
  height: calc(100% + 8px);
  width: calc(100% + 8px);

  & > * {
    margin: 0 8px 8px 0;
  }
}
