.m9-container {
  width: 100%;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  padding: 16px 19px;

  @screen md {
    padding: 24px 20px;
  }
}

.hidden-scrollbar {
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none;
}


.m9-columns {
  display: grid;
  gap: 1.5rem;

  &--4-8 {
    @extend .m9-columns;
    grid-template-columns: 384px 792px;
  }
  &--3-9 {
    @extend .m9-columns;
    grid-template-columns: 282px 894px;
  }
}

.m9-card {
  background: #ffffff;
  border: 1px solid $color-gray-150;
  box-sizing: border-box;
  box-shadow: $depth-2;
  border-radius: 16px;
}

.m9-radio-group-label {
  .MuiFormControlLabel-label {
    font-size: 1rem;
    line-height: 1.5rem;
    color: $color-gray-500;
  }
}
