@import "/src/ui/styles/variables";

.aside-active-icon {
    color: $color-primary-400;
    path {
        fill: $color-primary-400 !important;
    }
}

.aside-icon {
    color: $color-gray-400;
    path {
        fill: $color-gray-400 !important;
    }
}