@import "/src/ui/styles/variables";

.FormSelect {
  & .MuiSelect-icon {
    top: calc(50% - 16px);
  }

  & .MuiSelect-select {
    padding-right: 48px !important;
  }
}

.FormSelect-Dropdown {
  max-height: 50vh;
  margin-top: 2px;
  padding: 8px;
  background-color: $color-gray-100;
  border: 2px solid $color-gray-200;
  box-sizing: border-box;
  box-shadow: 0px 64px 64px -48px rgba(31, 47, 70, 0.12);
  border-radius: 12px;

  & .MuiList-root {
    padding: 0;
  }

  & .MuiMenuItem-root {
    padding: 8px 10px;
    color: $color-primary-600;
    font-size: 14px;
    line-height: 24px;
    border-radius: 8px;
    overflow: hidden;

    &:not(:last-of-type) {
      margin-bottom: 8px;
    }

    &:hover,
    &.Mui-selected:hover,
    &.Mui-selected:not(.Mui-focusVisible):hover,
    &.Mui-focusVisible {
      background-color: $color-gray-150;
    }

    &.Mui-selected {
      color: $color-primary-400;
      font-weight: 600;
      &:not(.Mui-focusVisible) {
        background-color: unset;
      }
    }
  }
}
