@import "/src/ui/styles/variables";

.icon {
  rect {
    stroke: $color-semantic-green-400;
  }
}

.icon > path {
  fill: $color-semantic-green-400;
}

.bpIcon {
  rect {
    stroke: $color-bullpen-primary-900;
  }
}

.bpIcon > path {
  fill: $color-bullpen-primary-900;
}

.disabledIcon {
  rect {
    stroke: $color-gray-light;
  }
}

.disabledIcon > path {
  fill: $color-gray-light;
}
