.m9-dealCategory {
  &--color {
    &-geographicalRegion {
      background-color: $color-semantic-green-400;
    }
    &-sector {
      background-color: $color-complementary-400;
    }
    &-equityType {
      background-color: $color-semantic-green-600;
    }
    &-assetType {
      background-color: $color-gray-300;
    }
    &-dealType {
      background-color: $color-secondary-200;
    }
  }
}
