@import "/src/ui/styles/variables";

.Radio {
  position: absolute;
  left: -9999px;

  & + label {
    position: relative;
    cursor: pointer;
    width: 24px;
    height: 24px;
    border: 2px solid $color-gray-200;
    border-radius: 100%;
  }

  &:checked + label {
    background-color: $color-primary;
    border: 0;
    transition: background-color 200ms ease-in;
  }

  &:disabled:checked + label {
    background-color: $color-gray-400;
  }

  &:disabled:not(:checked) + label {
    background-color: $color-gray-150;
    border-color: $color-gray-100;
  }
}

.RadioLabel {
  display: flex;
  align-items: center;
  justify-content: center;
}
