.AIButton {
  border: none;
  outline: none;
  position: relative;
  background: rgba(255, 255, 255, 0.1);
  color: white;
  font-family: "IBM Plex Sans";
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
  transition: background-color 300ms;

  &::after {
    content: " ";
    position: absolute;
    height: 1px;
    top: -0.5px;
    left: 0;
    width: 100%;
    transition: all 200ms ease;
    background: linear-gradient(
      90deg,
      rgba(255, 255, 255, 0) 15%,
      rgba(255, 255, 255, 0.4) 50%,
      rgba(255, 255, 255, 0) 85%
    );
  }

  &:hover {
    background: rgba(255, 255, 255, 0.2);

    &::after {
      transition: all 200ms ease;
      background: linear-gradient(
        90deg,
        rgba(255, 255, 255, 0) 15%,
        rgba(255, 255, 255, 0.3) 50%,
        rgba(255, 255, 255, 0) 85%
      );
    }
  }

  &:focus {
    box-shadow: 0px 0px 0px 3px rgba(255, 255, 255, 0.05);
    background: rgba(255, 255, 255, 0.1);
  }

  &:disabled {
    background: rgba(255, 255, 255, 0.05);
  }
}
