.Banner {
  @apply flex py-3 shrink-0 z-[60] snap-start;
}

.container {
  & > * {
    margin-left: auto;
    margin-right: auto;
  }
}
