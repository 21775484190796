@import "./variables";

.notistack-SnackbarContainer {
  top: 108px !important;
}

a {
  text-decoration: none;
  color: $color-complementary-400;

  &:hover {
    color: darken($color-complementary-400, 20); 
  }

  &:active {
    color: darken($color-complementary-400, 40); 
  }
}