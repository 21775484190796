.podcast-card-title {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  &.video-card-title {
    -webkit-line-clamp: 3;
  }
}

.podcast-card-description {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  & > div {
    font-size: 14px !important;
    color: #777E90 !important;
  }
  &.video-card-description {
    -webkit-line-clamp: 5;
  }
}
