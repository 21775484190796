@import "/src/ui/styles/variables";

.primary {
  fill: $color-bullpen-primary-950;
  box-shadow: inset 0 0 0 3px $color-bullpen-primary-500;

  &:hover {
    box-shadow: inset 0 0 0 3px $color-bullpen-primary-950;
  }
}

.primary svg {
  width: 14px;
}

.primary:hover {
  fill: $color-bullpen-primary-50;
}

.primaryDisabled {
  fill: $color-gray-400;
}

.primaryDisabled:hover {
  fill: $color-gray-400;
}
