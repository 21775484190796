@import "../../styles/_variables.scss";

$fontFamily: "IBM Plex Sans", sans-serif;
$fontFamilyMono: "IBM Plex Mono", monospace;

@mixin ContentStyles {
  font-family: $fontFamily;
  color: rgba(0, 0, 0, 0.87);
  line-height: 1.5em;
  font-size: 16px;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: $fontFamily;
    line-height: 1.5em;
    margin: 0;
    padding: 0;
    white-space: pre-wrap;
  }

  blockquote {
    border-left: 5px solid $color-gray-150;
    padding: 0;
    padding-left: 5px;
    margin-left: 28px;

    &,
    & span {
      font-family: $fontFamily;
      white-space: pre-wrap;
      line-height: 1.5em;
    }

    & span {
      font-size: inherit;
    }
  }

  pre {
    font-family: $fontFamilyMono;
    background: $color-gray-150;
    border-radius: 3px;
    padding: 4px 12px;
    white-space: pre-wrap;
    margin: 0;
    line-height: 1.5em;
  }

  h1 {
    font-weight: bold;
    font-size: 48px;
  }

  h2 {
    font-weight: bold;
    font-size: 36px;
  }

  h3 {
    font-weight: bold;
    font-size: 30px;
  }

  h4 {
    font-size: 24px;
  }

  h5 {
    font-size: 18px;
  }

  h6 {
    font-size: 16px;
  }

  p,
  div,
  ul,
  span,
  li {
    padding: 0;
    margin: 0;
    line-height: 1.5em;
  }

  p,
  span,
  li {
    white-space: pre-wrap;
  }
}

@mixin BorderStyles {
  border-width: 2px;
  border-style: solid;
  border-color: inherit;
  border-radius: 12px;

  transition: border-width 0s, border-color 0s;
}

.Wrapper {
  border-color: $color-gray-200;
  
  &:hover {
    border-color: $color-gray-300;
  }
}

.Editor {
  @include BorderStyles();
  @include ContentStyles();
  border-top-width: 0;
  padding: 12px 16px;
  width: 100%;
  box-sizing: border-box;
  position: relative;
  cursor: text;
  min-height: 154px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;

  :global {
    .public-DraftEditorPlaceholder-root {
      font-family: $fontFamily;
      font-size: 1em;
      font-weight: bold;
      color: $color-gray-400;
      opacity: 0.35;
    }

    .rdw-image-alignment-options-popup {
      display: flex;
      justify-content: space-between;
      left: 0;
      bottom: -36px;
      overflow: hidden;
      @include BorderStyles();
      border-radius: 6px;
      box-shadow: 0px 24px 32px -16px rgba(161, 174, 183, 0.15);

      .rdw-image-alignment-option {
        height: 32px;
        width: 32px;
        border: 0;

        &:hover,
        &:active {
          box-shadow: none;
          background-color: $color-gray-150;
        }
      }
    }
  }
}

.Toolbar {
  padding: 12px 9px;
  margin-bottom: -1px;
  @include BorderStyles();
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  font-family: $fontFamily;

  visibility: visible;
  position: sticky;
  top: 64px;
  width: auto;
  z-index: 99;

  :global {
    .rdw-option-wrapper,
    .rdw-dropdown-wrapper {
      border-width: 0;
      border-radius: 6px;
      border-color: transparent;
      transition: background-color ease-out 200ms;
    }

    .rdw-option-wrapper:hover,
    .rdw-dropdown-wrapper:hover,
    .rdw-option-wrapper:active,
    .rdw-option-active:hover {
      box-shadow: none;
      background-color: $color-gray-200;
    }

    .rdw-option-active {
      box-shadow: none;
      background-color: $color-gray-150;
    }

    .rdw-fontsize-dropdown {
      width: 50px;
    }

    .rdw-block-dropdown {
      width: 120px;
    }

    .rdw-dropdown-wrapper {
      border-width: 0;

      a {
        color: black;
      }
    }
  }
}

.Viewer {
  @include ContentStyles();

  ul {
    list-style-position: outside;
    margin-left: 1.5em;
  }
}

