@import "/src/ui/styles/variables";

.Content {
  background: url("../../../assets/svg/BG\ lines.svg");
}

.IconWrapper {
  width: 64px;
  height: 64px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 16px;
  border: 1px solid $color-gray-200;
  background: white;
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.1) inset, 0px 0px 0px 2px white;

  svg {
    font-size: 24px;
  }
}
