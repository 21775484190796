@import "/src/ui/styles/variables";

.navigationPrimaryItem {
  @apply flex flex-col p-2 cursor-pointer rounded-md max-w-[368px];

  transition: 200ms;

  & svg {
    path {
      fill: $color-gray-400 !important;
    }
  }

  &:hover {
    svg {
      path {
        fill: $color-primary-400 !important;
      }
    }
  }
}
