.cardWrapper {
  width: 300px;
  min-height: 120px;
}

.paper {
  box-shadow: 0px 24px 32px -16px rgba(161, 174, 183, 0.15);
}

.followButton {
  padding: 12px 26px;
  height: 40px;
}
